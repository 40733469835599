/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica Neue Light"), local("HelveticaNeue-Light"), url(fonts/HelveticaNeue-Light.woff) format("woff"); }

*,
body,
html,
*::after,
*::before {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: 'Helvetica Neue', Roboto, sans-serif;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  outline: 0;
  outline: none; }

html {
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  background-color: black; }

body {
  height: 100%;
  min-height: 100%;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black; }

:root {
  --edge-width: 3px; }

.info {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  opacity: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  -webkit-transition: opacity 0.4s cubic-bezier(0.02, 0.01, 0.47, 1);
  transition: opacity 0.4s cubic-bezier(0.02, 0.01, 0.47, 1);
  padding-left: 3.75vw;
  padding-right: 3.75vw; }
  .info::before {
    height: calc(var(--vh, 1vh) * 4.39);
    content: '';
    width: 100%;
    position: absolute;
    top: -1px;
    left: 0;
    background-color: white;
    z-index: 2; }
  .info__content {
    background-color: white;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding-left: 9.75vw;
    padding-right: 9.75vw; }
  .info__second-page, .info__first-page {
    min-height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-bottom: calc(var(--vh, 1vh) * 4.5); }
  .info__description {
    font-size: 3.9vmin;
    line-height: 2.8em;
    font-weight: 300;
    letter-spacing: 0.05em;
    font-size: calc(var(--vh, 1vh) * 1.95);
    text-align: center;
    margin-bottom: calc(var(--vh, 1vh) * 5); }
  .info__schedule {
    margin-bottom: 0; }
  .info__header {
    font-weight: 700;
    text-align: center;
    color: #000000;
    font-size: 3.9vmin;
    line-height: 2.4em;
    letter-spacing: 0.05em; }
  .info__artists {
    margin-bottom: calc(var(--vh, 1vh) * 6); }
  .info__director {
    margin-bottom: calc(var(--vh, 1vh) * 2.5); }
  .info__small-text {
    color: black;
    font-size: calc(var(--vh, 1vh) * 1.95);
    line-height: 2.8em;
    font-weight: 300;
    letter-spacing: 0.05em;
    display: block;
    text-align: center;
    text-decoration: none;
    -webkit-transition: color 0.3s;
    transition: color 0.3s; }
    .info__small-text:hover {
      color: #666; }
  .info__second-page-text {
    color: #000000;
    font-size: calc(var(--vh, 1vh) * 1.95);
    line-height: 2.45em;
    font-weight: 300;
    letter-spacing: 0.05em;
    display: block;
    text-align: center;
    text-decoration: none; }
  .info__email {
    color: black;
    text-decoration: none;
    margin-bottom: calc(var(--vh, 1vh) * 7); }
  .info__address {
    margin-bottom: calc(var(--vh, 1vh) * 8.5); }
  .info__instagram {
    display: inline-block; }
  .info__instagram-svg {
    width: 7.5vmin;
    height: 7.5vmin; }
  @media (orientation: landscape), screen and (min-width: 1280px) {
    .info {
      display: none; } }
  @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait), (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    .info {
      display: initial; }
      .info__small-text, .info__second-page-text, .info__description, .info__header {
        font-size: 2.6vmin;
        letter-spacing: 0.05em; }
      .info__small-text, .info__description {
        line-height: 2.8em; }
      .info__second-page-text, .info__header {
        line-height: 2.3em; }
      .info__instagram-svg {
        width: 4.6875vmin;
        height: 4.6875vmin; }
      .info__first-page {
        padding-bottom: 5vmin; }
      .info__second-page {
        padding-bottom: 12vmin; }
      .info__artists {
        margin-bottom: 6vmax; } }

.container {
  width: 100%;
  height: 100%;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 250%;
  background-position: 50%; }
  .container__content {
    height: 100%;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    position: relative; }
    .container__content--right {
      display: block; }
  @media (orientation: landscape), screen and (min-width: 1280px) {
    .container__content {
      display: block;
      height: 100%;
      -webkit-box-flex: 1;
          -ms-flex-positive: 1;
              flex-grow: 1; }
    .container__background {
      position: absolute;
      background-repeat: no-repeat;
      background-size: 300%;
      background-position: 50%;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      -webkit-transition: opacity 0.85s cubic-bezier(0.455, 0.03, 0.515, 0.955);
      transition: opacity 0.85s cubic-bezier(0.455, 0.03, 0.515, 0.955); }
      .container__background--1 {
        opacity: 1;
        background-color: white; }
      .container__background--3 {
        opacity: 0;
        background-color: black;
        -webkit-animation: border-fade 2.7s cubic-bezier(0.455, 0.03, 0.515, 0.955);
                animation: border-fade 2.7s cubic-bezier(0.455, 0.03, 0.515, 0.955);
        -webkit-transition: initial;
        transition: initial; } }
  @media (device-width: 1366px) and (device-height: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape), (min-device-width: 1024px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    .container__background--3 {
      -webkit-animation: initial;
              animation: initial; } }

@-webkit-keyframes border-fade {
  0% {
    opacity: 0; }
  31.4815% {
    opacity: 1; }
  68.5185% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes border-fade {
  0% {
    opacity: 0; }
  31.4815% {
    opacity: 1; }
  68.5185% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.logo {
  width: 27.14vmin;
  padding-top: calc(var(--vh, 1vh) * 8.78);
  height: auto;
  cursor: pointer;
  background-color: white;
  text-align: center; }
  .logo__svg {
    width: 100%;
    height: 100%; }
    .logo__svg .st0 {
      fill: none;
      stroke: #000000; }
  @media (orientation: landscape), screen and (min-width: 1280px) {
    .logo {
      z-index: 1;
      width: 100%;
      background-color: transparent;
      margin: 0;
      padding: 0;
      pointer-events: none; }
      .logo__wrapper {
        width: 4.5vw; }
      .logo__svg {
        height: auto; } }
  @media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (orientation: portrait), (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    .logo {
      padding-top: 11.7vmin;
      width: 24.7vmin; } }
  @media (max-width: 1024px) and (orientation: landscape), (device-width: 1366px) and (device-height: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape), (min-device-width: 1024px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    .logo__wrapper {
      width: 9.36vw; } }

.slider {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: opacity 0.4s cubic-bezier(0.02, 0.01, 0.47, 1);
  transition: opacity 0.4s cubic-bezier(0.02, 0.01, 0.47, 1);
  -webkit-transition-delay: 0.4s;
          transition-delay: 0.4s;
  z-index: 10;
  opacity: 1;
  background-color: white; }
  .slider::before {
    height: calc(var(--vh, 1vh) * 4.39);
    content: '';
    width: 100%;
    position: absolute;
    top: -1px;
    left: 0;
    background-color: white;
    z-index: 2; }
  .slider__wrapper {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    overflow: hidden;
    height: 100%;
    width: 100%;
    padding-left: 3.75vw;
    padding-right: 3.75vw; }
  .slider__content {
    padding-left: 9.75vw;
    padding-right: 9.75vw;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    height: 100%; }
  .slider--slides-visible .slide {
    opacity: 1 !important; }
  @media (orientation: landscape), screen and (min-width: 1280px) {
    .slider {
      position: relative;
      padding: 0;
      background-color: transparent; }
      .slider::before {
        display: none; }
      .slider__content {
        position: relative;
        padding: 0;
        overflow-y: scroll;
        margin-right: -17px;
        padding-right: 17px;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none; }
        .slider__content::-webkit-scrollbar {
          display: none; }
      .slider__wrapper {
        padding: 0;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        overflow: hidden;
        cursor: pointer; } }
  @media (max-width: 1024px) and (orientation: landscape), (device-width: 1366px) and (device-height: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape), (min-device-width: 1024px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    .slider__wrapper {
      pointer-events: none; }
    .slider__content {
      pointer-events: auto; } }

.slide {
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  opacity: 0; }
  .slide--visible {
    opacity: 1; }
  .slide__artist {
    font-size: 4.3vw;
    letter-spacing: 0.091em;
    line-height: 1.9545em;
    text-decoration: none;
    color: black; }
  .slide__portfolio {
    color: #9b9b9b;
    text-decoration: none;
    font-size: 4.3vw;
    letter-spacing: 0.091em;
    line-height: 1.9545em;
    -webkit-transition: color .4s;
    transition: color .4s; }
    .slide__portfolio:hover {
      color: black; }
  .slide__img {
    width: 100%;
    -ms-flex-negative: 1;
        flex-shrink: 1;
    height: 70%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    margin: 0;
    margin-bottom: calc(var(--vh, 1vh) * 2); }
    .slide__img--desktop {
      display: none; }
  .slide__arrow--down {
    margin-top: auto; }
  @media (orientation: landscape), screen and (min-width: 1280px) {
    .slide {
      opacity: 0;
      z-index: -1;
      -webkit-transition-delay: initial;
              transition-delay: initial;
      padding: 0; }
      .slide__img {
        margin: 0;
        height: 100%;
        background-image: none !important; }
        .slide__img--desktop {
          width: 100%;
          display: block;
          height: 100%;
          margin-left: auto;
          margin-right: auto; }
      .slide__artist--mobile {
        display: none; }
      .slide__portfolio--mobile {
        display: none; } }
  @media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2), (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    .slide {
      padding-bottom: 4vmin; }
      .slide__artist {
        font-size: 2.8646vw;
        letter-spacing: 0.091em;
        line-height: 1.9545em; }
      .slide__portfolio {
        font-size: 2.8646vw;
        letter-spacing: 0.091em;
        line-height: 1.9545em; }
      .slide__img {
        background-size: 90%; } }

@-webkit-keyframes show {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes show {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.show-info .info {
  opacity: 1;
  z-index: 11;
  -webkit-transition: opacity 0.4s cubic-bezier(0.02, 0.01, 0.47, 1);
  transition: opacity 0.4s cubic-bezier(0.02, 0.01, 0.47, 1);
  -webkit-transition-delay: 0.4s;
          transition-delay: 0.4s; }

.show-info .slider {
  opacity: 0;
  -webkit-transition-delay: initial;
          transition-delay: initial; }

@media (orientation: landscape), screen and (min-width: 1280px) {
  .show-info .artist {
    opacity: 0 !important;
    z-index: 1 !important;
    -webkit-transition-delay: 0s;
            transition-delay: 0s;
    pointer-events: none; }
  .show-info .nav {
    opacity: 0 !important;
    z-index: -1 !important;
    -webkit-transition-delay: 0s;
            transition-delay: 0s; }
  .show-info .slider {
    opacity: 0;
    z-index: -1;
    -webkit-transition-delay: 0s;
            transition-delay: 0s; }
  .show-info .contacts {
    display: block;
    opacity: 1;
    z-index: 6;
    -webkit-transition-delay: 0.4s;
            transition-delay: 0.4s;
    pointer-events: auto; }
  .show-info .about {
    opacity: 1;
    z-index: 6;
    -webkit-transition-delay: 0.4s !important;
            transition-delay: 0.4s !important; }
  .show-info .column-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    z-index: 11;
    opacity: 1; }
  .show-info .column-left {
    z-index: 11;
    opacity: 1; } }

.show-slide {
  opacity: 1; }
  @media (orientation: landscape), screen and (min-width: 1280px) {
    .show-slide {
      opacity: 0;
      z-index: 1; }
      .show-slide#slide0 {
        opacity: 0;
        -webkit-animation: show-slide 0.85s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1.85s forwards;
                animation: show-slide 0.85s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1.85s forwards; } }
  @media (max-width: 1024px) and (orientation: landscape), (device-width: 1366px) and (device-height: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape), (min-device-width: 1024px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    .show-slide#slide0 {
      opacity: 1; } }

@-webkit-keyframes show-slide {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes show-slide {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.d__scene {
  width: 100%;
  height: 100%; }

.d__cube {
  width: 100%;
  height: 100%; }

.d__cube-face {
  display: none; }

.d__cube-face--back {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: white; }

@media (orientation: landscape), screen and (min-width: 1280px) {
  .d__scene {
    width: 100%;
    height: 100%; }
  .d__cube {
    width: 100%;
    height: 100%;
    position: relative;
    -webkit-transition: -webkit-transform 0.85s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    transition: -webkit-transform 0.85s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    transition: transform 0.85s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    transition: transform 0.85s cubic-bezier(0.455, 0.03, 0.515, 0.955), -webkit-transform 0.85s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    -webkit-font-smoothing: subpixel-antialiased;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .d__cube--big {
      -webkit-transform: scale(2.08);
              transform: scale(2.08); }
      .d__cube--big .column-left {
        z-index: 1;
        opacity: 1; }
      .d__cube--big .logo {
        pointer-events: auto; }
      .d__cube--big .artist {
        display: block;
        z-index: 11;
        opacity: 1; }
      .d__cube--big .column-right {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        z-index: 11;
        opacity: 1; }
      .d__cube--big .slider__content {
        z-index: 1; }
  .d__cube-face {
    display: block;
    position: absolute;
    background-color: transparent;
    -webkit-transition: width 0.85s ease-out, height 0.85s ease-out, top 0.85s ease-out, left 0.85s ease-out;
    transition: width 0.85s ease-out, height 0.85s ease-out, top 0.85s ease-out, left 0.85s ease-out; }
    .d__cube-face::before, .d__cube-face::after {
      -webkit-transition: width 0.85s ease-out, height 0.85s ease-out, top 0.85s ease-out;
      transition: width 0.85s ease-out, height 0.85s ease-out, top 0.85s ease-out; }
  .d__cube-face--top-right {
    width: calc(25% + var(--edge-width, 5px));
    height: calc(25% + var(--edge-width, 5px));
    top: 0;
    right: 0; }
    .d__cube-face--top-right::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% - var(--edge-width, 5px));
      height: calc(100% - var(--edge-width, 5px));
      border-top: 25vh solid white;
      border-right: 25vw solid transparent; }
    .d__cube-face--top-right::before {
      content: '';
      position: absolute;
      top: var(--edge-width, 5px);
      right: 0;
      width: calc(100% - var(--edge-width, 5px));
      height: calc(100% - var(--edge-width, 5px));
      border-bottom: 25vh solid white;
      border-left: 25vw solid transparent; }
  .d__cube-face--back {
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    width: 50%;
    height: 50%;
    position: relative;
    border: var(--edge-width, 5px) solid transparent;
    -webkit-transition: border 0.85s ease-out;
    transition: border 0.85s ease-out;
    background-color: white;
    background-clip: padding-box;
    padding: 4.9vmin 6.1vmin 4.9vmin 6.1vmin; }
  .d__cube-face--top-left {
    width: calc(25% + var(--edge-width, 5px));
    height: calc(25% + var(--edge-width, 5px));
    left: 0;
    top: 0; }
    .d__cube-face--top-left::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: calc(100% - var(--edge-width, 5px));
      height: calc(100% - var(--edge-width, 5px));
      border-top: 25vh solid white;
      border-left: 25vw solid transparent; }
    .d__cube-face--top-left::before {
      content: '';
      position: absolute;
      top: var(--edge-width, 5px);
      left: 0;
      width: calc(100% - var(--edge-width, 5px));
      height: calc(100% - var(--edge-width, 5px));
      border-bottom: 25vh solid white;
      border-right: 25vw solid transparent; }
  .d__cube-face--bottom-left {
    width: calc(25% + var(--edge-width, 5px));
    height: calc(25% + var(--edge-width, 5px));
    bottom: 0;
    left: 0; }
    .d__cube-face--bottom-left::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% - var(--edge-width, 5px));
      height: calc(100% - var(--edge-width, 5px));
      border-top: 25vh solid white;
      border-right: 25vw solid transparent; }
    .d__cube-face--bottom-left::before {
      content: '';
      position: absolute;
      top: var(--edge-width, 5px);
      right: 0;
      width: calc(100% - var(--edge-width, 5px));
      height: calc(100% - var(--edge-width, 5px));
      border-bottom: 25vh solid white;
      border-left: 25vw solid transparent; }
  .d__cube-face--bottom-right {
    width: calc(25% + var(--edge-width, 5px));
    height: calc(25% + var(--edge-width, 5px));
    bottom: 0;
    right: 0; }
    .d__cube-face--bottom-right::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: calc(100% - var(--edge-width, 5px));
      height: calc(100% - var(--edge-width, 5px));
      border-top: 25vh solid white;
      border-left: 25vw solid transparent; }
    .d__cube-face--bottom-right::before {
      content: '';
      position: absolute;
      top: var(--edge-width, 5px);
      left: 0;
      width: calc(100% - var(--edge-width, 5px));
      height: calc(100% - var(--edge-width, 5px));
      border-bottom: 25vh solid white;
      border-right: 25vw solid transparent; }
  .d__cube-face--middle-left {
    width: 25%;
    height: calc(50% - 2 * var(--edge-width, 5px));
    background-color: white;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    top: calc(25% + var(--edge-width, 5px));
    left: 0; }
    .d__cube-face--middle-left::before {
      content: '';
      position: absolute;
      top: -2px;
      left: 0;
      height: 2px;
      width: calc(100% - 2px);
      background-color: white;
      z-index: 30; }
    .d__cube-face--middle-left::after {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 0;
      height: 2px;
      width: calc(100% - 2px);
      background-color: white;
      z-index: 30; }
  .d__cube-face--middle-right {
    width: 25%;
    height: calc(50% - 2 * var(--edge-width, 5px));
    background-color: white;
    top: calc(25% + var(--edge-width, 5px));
    right: 0; }
    .d__cube-face--middle-right::before {
      content: '';
      position: absolute;
      top: -1px;
      right: 0;
      height: 2px;
      width: calc(100% - 2px);
      background-color: white;
      z-index: 30; }
    .d__cube-face--middle-right::after {
      content: '';
      position: absolute;
      bottom: -1px;
      right: 0;
      height: 2px;
      width: calc(100% - 2px);
      background-color: white;
      z-index: 30; }
  .d__cube-face--middle-top {
    width: calc(50% - 2 * var(--edge-width, 5px));
    height: 25%;
    background-color: white;
    top: 0;
    left: calc(25% + var(--edge-width, 5px)); }
    .d__cube-face--middle-top::before {
      content: '';
      position: absolute;
      left: -2px;
      height: calc(100% - 2px);
      width: 4px;
      background-color: white;
      z-index: 30; }
    .d__cube-face--middle-top::after {
      content: '';
      position: absolute;
      right: -2px;
      height: calc(100% - 2px);
      width: 4px;
      background-color: white;
      z-index: 30; }
  .d__cube-face--middle-bottom {
    width: calc(50% - 2 * var(--edge-width, 5px));
    height: 25%;
    background-color: white;
    bottom: 0;
    left: calc(25% + var(--edge-width, 5px)); }
    .d__cube-face--middle-bottom::before {
      content: '';
      position: absolute;
      left: -2px;
      height: calc(100% - 2px);
      width: 4px;
      background-color: white;
      bottom: 0;
      z-index: 30; }
    .d__cube-face--middle-bottom::after {
      content: '';
      position: absolute;
      bottom: 0;
      right: -2px;
      height: calc(100% - 2px);
      width: 4px;
      background-color: white;
      z-index: 30; } }

@media (max-width: 1024px) and (orientation: landscape), (device-width: 1366px) and (device-height: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape), (min-device-width: 1024px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .d__cube {
    -webkit-transition: initial;
    transition: initial;
    background-color: white; }
    .d__cube--big {
      -webkit-transform: initial;
              transform: initial; }
    .d__cube .column-left {
      z-index: 1;
      opacity: 1; }
    .d__cube .logo {
      pointer-events: auto; }
    .d__cube .artist {
      display: block;
      z-index: 11;
      opacity: 1; }
    .d__cube .column-right {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      z-index: 11;
      opacity: 1; }
    .d__cube .slider__content {
      z-index: 1; }
  .d__cube-face {
    display: none;
    -webkit-transition: initial;
    transition: initial; }
    .d__cube-face:before, .d__cube-face:after {
      -webkit-transition: initial;
      transition: initial; }
    .d__cube-face--back {
      width: 100%;
      height: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      padding: 9vmin 11vmin;
      border: none; }
  .d__cube-face--back:before {
    -webkit-animation: none;
            animation: none;
    border: none;
    background-clip: border-box; } }

.artist {
  display: none; }
  @media (orientation: landscape), screen and (min-width: 1280px) {
    .artist {
      height: 20%;
      position: relative;
      opacity: 0;
      z-index: -1;
      -webkit-transition: opacity 0.4s cubic-bezier(0.02, 0.01, 0.47, 1);
      transition: opacity 0.4s cubic-bezier(0.02, 0.01, 0.47, 1);
      -webkit-transition-delay: 0.4s;
              transition-delay: 0.4s; }
      .artist__item {
        position: absolute;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: end;
            -ms-flex-pack: end;
                justify-content: flex-end;
        left: 0;
        bottom: 0;
        opacity: 0;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: 0; }
        .artist__item--active {
          z-index: 1;
          position: static; }
      .artist__name {
        font-size: 0.5279vw;
        letter-spacing: 0.1333em;
        line-height: 2em;
        -webkit-transition: opacity .85s;
        transition: opacity .85s;
        text-decoration: none;
        color: black;
        display: block;
        text-align: left;
        white-space: nowrap;
        overflow: visible; }
        .artist__name:hover ~ .artist__portfolio {
          color: black; }
      .artist__portfolio {
        text-align: left;
        font-size: 0.5279vw;
        letter-spacing: 0.1333em;
        line-height: 2em;
        color: #9b9b9b;
        text-decoration: none;
        display: block; }
        .artist__portfolio:hover {
          color: black; } }
  @media (max-width: 1024px) and (orientation: landscape), (device-width: 1366px) and (device-height: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape), (min-device-width: 1024px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    .artist__name {
      font-size: 1.1vw; }
    .artist__portfolio {
      font-size: 1.1vw; } }

.nav {
  display: none; }
  @media (orientation: landscape), screen and (min-width: 1280px) {
    .nav {
      -webkit-transition: opacity 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
      transition: opacity 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
      -webkit-transition-delay: 0.4s;
              transition-delay: 0.4s;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: end;
          -ms-flex-align: end;
              align-items: flex-end;
      position: absolute;
      z-index: 1;
      top: 50%;
      right: -0.8vw;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%); }
      .nav__item {
        font-size: 0.53vw;
        line-height: 1.33em;
        letter-spacing: 0.089em;
        color: #9b9b9b;
        text-align: right;
        text-decoration: none; }
        .nav__item:hover {
          color: black; }
      .nav__item ~ .nav__item {
        margin-top: 1.4em; } }
  @media (max-width: 1024px) and (orientation: landscape), (device-width: 1366px) and (device-height: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape), (min-device-width: 1024px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    .nav {
      right: -2.15vw; }
      .nav__item {
        font-size: 1.1vw; } }

.scrolled {
  color: black; }

.column-right {
  display: none; }
  @media (orientation: landscape), screen and (min-width: 1280px) {
    .column-right {
      position: relative;
      width: 25%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      opacity: 0;
      z-index: -1;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column; } }

@media (orientation: landscape), screen and (min-width: 1280px) {
  .column-left {
    z-index: -1;
    width: 25%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    opacity: 0;
    margin: 0;
    padding: 0;
    margin-bottom: 0;
    -webkit-transition: opacity 0.4s cubic-bezier(0.02, 0.01, 0.47, 1);
    transition: opacity 0.4s cubic-bezier(0.02, 0.01, 0.47, 1); } }

@media (orientation: landscape), screen and (min-width: 1280px) and (orientation: portrait) {
  .contacts {
    position: absolute;
    bottom: 0;
    right: 0;
    opacity: 0;
    z-index: -1;
    pointer-events: none;
    text-align: right;
    padding-right: 0.45vw; }
    .contacts__text {
      font-size: 0.5279vw;
      letter-spacing: 0.06666em;
      text-decoration: none;
      color: black;
      line-height: 1em;
      text-align: right;
      display: block;
      white-space: nowrap;
      margin-bottom: calc(var(--vh, 1vh) * 1.8);
      -webkit-transition: color 0.3s;
      transition: color 0.3s; }
      .contacts__text:hover {
        color: #666; }
    .contacts__instagram {
      text-align: right;
      margin-top: 0.28vw;
      margin-bottom: 0.8vw;
      display: block; }
    .contacts__instagram-svg {
      width: 1vw;
      height: 1vw; } }

@media (max-width: 1024px) and (orientation: landscape), (device-width: 1366px) and (device-height: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape), (min-device-width: 1024px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .contacts__text {
    font-size: 1.1vw;
    margin-bottom: calc(var(--vh, 1vh) * 3.744); }
  .contacts__instagram-svg {
    width: 2vw;
    height: 2vw; }
  .contacts__instagram {
    text-align: right;
    margin-top: 0.5824vw;
    margin-bottom: 2.2vw; } }

.about {
  display: none; }
  @media (orientation: landscape), screen and (min-width: 1280px) and (orientation: portrait) {
    .about {
      display: block;
      height: 100%;
      width: 100%;
      padding-top: 11.8vmin;
      padding-left: 4vmax;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: -1;
      -webkit-transition: opacity 0.4s cubic-bezier(0.02, 0.01, 0.47, 1);
      transition: opacity 0.4s cubic-bezier(0.02, 0.01, 0.47, 1); }
      .about__content {
        margin-right: auto;
        margin-left: auto; }
      .about__text {
        font-size: 0.5279vw;
        line-height: 3em;
        letter-spacing: 0.0666em; } }
  @media (max-width: 1024px) and (orientation: landscape), (device-width: 1366px) and (device-height: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape), (min-device-width: 1024px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    .about {
      padding-top: 24.544vmin;
      padding-left: 8.32vmax; }
      .about__text {
        font-size: 1.1vw; } }

.intro {
  z-index: -1;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0; }
  @media (orientation: landscape), screen and (min-width: 1280px) {
    .intro {
      background-image: url(img/logo.svg);
      width: 100%;
      height: 100%;
      background-position: 50%;
      background-repeat: no-repeat;
      z-index: -1;
      background-size: 32%;
      opacity: 0;
      -webkit-animation: hide-intro 1.85s cubic-bezier(0.455, 0.03, 0.515, 0.955) forwards;
              animation: hide-intro 1.85s cubic-bezier(0.455, 0.03, 0.515, 0.955) forwards;
      background-color: white; } }
  @media (max-width: 1024px), (device-width: 1366px) and (device-height: 1024px), (min-device-width: 768px) and (max-device-width: 1024px) {
    .intro {
      display: none; } }

@-webkit-keyframes hide-intro {
  0% {
    opacity: 1;
    z-index: 11; }
  0.459459% {
    opacity: 1;
    z-index: 11; }
  100% {
    opacity: 0;
    z-index: -1; } }

@keyframes hide-intro {
  0% {
    opacity: 1;
    z-index: 11; }
  0.459459% {
    opacity: 1;
    z-index: 11; }
  100% {
    opacity: 0;
    z-index: -1; } }
